<template>
  <div class="log">
        <fromSearch :object_='object_' @searchFun='getList'/>
      <avue-crud ref="crud" :page="page" :data="tableData"
        :table-loading="tableLoading"
        :option="tableOption1"
        :permission="permissionList"
        @search-reset='refreshChange'
        @refresh-change="getList(1)"
        @size-change="sizeChange"
        @current-change="currentChange" >
        <template #menu-left>
            <el-button v-if="sys_log_import" class="filter-item"
                plain type="primary" 
                @click="exportExcel" >{{$t("button.imports")}} </el-button>
            <el-button class="filter-item" v-if="sys_log_del" 
                plain type="primary"  @click="handleDelLogs" >{{$t("button.moreDel")}} </el-button>
        </template>
        <template #logType="scope">
            {{  getDataAboutText(8,scope.row.logType)}}
        </template>
        <template #caozuo ="scope">
            <el-button v-if="sys_log_del" text type="primary" size="small"
                 @click="handleDel(scope.row)">{{$t("button.del")}} </el-button>
        </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
      </avue-crud>
  </div>
</template>

<script setup>
import { ref,reactive,getCurrentInstance } from 'vue'
import { tableOption } from "@/const/crud/log";
import Api_ from '@/api/index'
import { downBlobFile } from '@/utils/util'
import { ElNotification,ElMessageBox } from 'element-plus'
import fromSearch from '@/components/fromSearch.vue'
import { object } from '@/const/from/admin/log'
const object_ = ref(object);
const { $t } = getCurrentInstance().proxy;  
import { turnTimeFun } from '@/utils/util.js'
import { getDataAboutText } from '@/utils/dataBind/parmsView'

const page = reactive({
    total: 0, // 总页数
    currentPage: 1, // 当前页数
    pageSize: 20, // 每页显示多少条,
    isAsc: false // 是否倒序
})
const crud = ref(null)
const tableData = ref([])
const tableLoading = ref(false)
const tableOption1 = tableOption

const sys_log_import =ref(true)
const sys_log_del =ref(true)
const searchForm = ref({})
const permissionList = ()=>{//权限控制
    return {
        delBtn: true
    }
}
const getList = (_qw)=>{
    let obj_ = {
        current:page.currentPage,
        size:page.pageSize,
        descs: "create_time"
    }
    obj_ = Object.assign({}, obj_, _qw)
    tableLoading.value = true
    Api_.logList(obj_).then(res=>{
        if(res.code === 0){
            tableLoading.value = false
            tableData.value = res.data.records
            page.total = res.data.total
        }
    })
}
const refreshChange = ()=>{
    searchForm.value = {}
    getList()
}
const handleDel = (row)=>{
    ElMessageBox.confirm($t("alert.selEnter"), $t("tyle_.warn"), {
        confirmButtonText: $t("button.enter"),
        cancelButtonText: $t("button.close"),
        type: 'warning',
    }).then(()=>{
        Api_.delListlog(row.id).then(res=>{
            if(res.code ===0 ){
                getList()
                ElNotification({
                    message: $t("alert.succDel"),
                    type: 'success',
                })
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
    })
}
const exportExcel = ()=>{
    downBlobFile('/admin/log/export', $t("downName.name_2")+".xlsx",searchForm.value)
}
const handleDelLogs = ()=>{
    if (crud.value.tableSelect.length === 0) {
        ElNotification({
            message: $t("adminSole.tit1"),
            type: 'error',
        })
        return false
      }
      let logIds = []
      for (const row of crud.value.tableSelect) {
        logIds.push(row.id)
      }
      ElMessageBox.confirm($t("alert.selEnter"),$t("tyle_.warn"), {
        confirmButtonText: $t("button.enter"),
        cancelButtonText: $t("button.close"),
        type: 'warning',
    }).then(()=>{
        Api_.delListlogs(logIds).then(res=>{
            if(res.code ===0 ){
                getList()
                ElNotification({
                    message: $t("alert.succDel"),
                    type: 'success',
                })
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
    })
}
const sizeChange = (pageSize)=>{ 
    page.pageSize = pageSize;
    getList()
}
const currentChange = (currentPage)=>{ 
    page.currentPage = currentPage;
    getList()
}
getList()
</script>
<style lang="scss" scoped>
    :deep(.avue-crud__tip){
        display: none;
    }
</style>
