import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { parmsArray } from '@/config/data'

export const object = [{
    type: 'select',
    name: t("adminSole.name2"),
    prop: 'logType',
    isParms: parmsArray[8]
},{
    type: 'datetime',
    name: t("adminSole.name8"),
    prop: 'createTime'
}]