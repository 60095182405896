
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption = {
    selection: true,
    height: 'auto',
    // reserveSelection: true,
    border: true,
    stripe: true,
    menuAlign: 'center',
    menuWidth: 150,
    align: 'center',
    showClomnuBtn: false,
    // searchSize: 'mini',
    searchMenuSpan: 6,
    addBtn: false,
    editBtn: false,
    viewBtn: false,
    delBtn: false,
    menu:false,
    columnBtn: false,
    column: [{
        width: 100,
      label: t("adminSole.name2"),
      prop: 'logType',
    //   search: true
    }, {
      label: t("adminSole.name3"),
      prop: 'title'
    }, {
      label: t("adminSole.name4"),
      prop: 'remoteAddr'
    }, {
      label: t("adminSole.name5"),
      prop: 'method'
    }, {
      label: t("adminSole.name6"),
      prop: 'serviceId'
    }, {
      width: 200,
      label: t("adminSole.name7")  + '(ms)',
      prop: 'time'
    }, {
      label: t("adminSole.name8"),
      prop: 'createTime',
    },{
        width: 140,
        label: t("button.operation"),
        prop: 'caozuo',
        fixed:'right',
      },]
  }
  